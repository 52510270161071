import './src/stylesheets/styles.scss'
import '@fontsource/licorice'

import { stickUtmParameters } from './src/utm'
import { freshpaint } from './src/freshpaint'
import { amplitudeAnalytics, ENTRY_FORM_AMPLITUDE_OPTIONS } from './src/amplitude'

import { AmplitudeContextProvider } from '@pathccm/amplitude-browser'
import React from 'react'

export const onClientEntry = (): void => {
  freshpaint()
  stickUtmParameters()
  amplitudeAnalytics()
}

export const wrapRootElement = ({ element }) => {
  return (
    <AmplitudeContextProvider amplitudeOptions={ENTRY_FORM_AMPLITUDE_OPTIONS}>
      {element}
    </AmplitudeContextProvider>
  )
}
